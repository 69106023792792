<template>
  <div class="section-content section-pickup pt-4 pb-5 w-100 container">
    <b-container class="container-xlg m-auto pl-xxl-0 pr-xxl-0">
      <div class="top-event d-flex flex-column flex-md-column">
        <esports-title
          title="Privacy Policy"
          class="pb-2 pt-2 mb-2 mt-2 mt-xxl-4 pb-xxl-5 article-page"
          sub_title="プライバシーポリシー"
        />
      </div>
      <div class="term-content">
          <p>株式会社メルメクス（以下、「当社」といいます。）は、本ウェブサイト及びアプリ上で提供するサービス（以下、「本サービス」といいます。）において、以下のプライバシーポリシー（以下、「本ポリシー」といいます。）に基づき、お客様（以下、「お客様」といいます。）から取得した、お客様のプライバシーに関する情報（以下「プライバシー情報」といいます。）を取り扱います。 本サービスのご利用をされるためには。下記の本ポリシーを熟読のうえ、内容に同意していただく必要があります。同意いただけない場合は、本サービスへのアクセスおよび本サービスのご利用はお断り致します。</p>

          <p>
                <b>第1条（プライバシー情報）</b> <br />
                １.プライバシー情報のうち「個人情報」とは、個人情報の保護に関する法律（平成15年法律第57号、以下「個人情報保護法」といいます）2条1項にいう「個人情報」を指します。<br />
                ２.プライバシー情報のうち「履歴情報および特性情報」とは、上記に定める「個人情報」以外のものをいい、ご利用いただいたサービスやご購入いただいた商品、ご覧になったページや広告の履歴、お客様が検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、お客様のIPアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。
          </p>
         <p>
               <b>第２条（プライバシー情報の収集方法）</b><br />
               １. 当社は、お客様が利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレス、銀行口座番号、クレジットカード番号、運転免許証番号などの個人情報をお尋ねすることがあります。また、お客様と提携先などとの間でなされたお客様の個人情報を含む取引記録や、決済に関する情報を当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから収集することがあります。<br />
               ２. 当社は、お客様について、利用したサービスやソフトウエア、購入した商品、閲覧したページや広告の履歴、検索した検索キーワード、利用日時、利用方法、利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態、利用に際しての各種設定情報なども含みます）、IPアドレス、クッキー情報、位置情報、端末の個体識別情報などの履歴情報および特性情報を、お客様が当社や提携先のサービスを利用しまたはページを閲覧する際に収集します。<br />
         </p>
         <p>
               <b>第３条（プライバシー情報を収集・利用する目的）</b><br />
               当社がプライバシー情報を収集・利用する目的は、以下のとおりです。利用目的を超えて利用することはありません。<br />
               （1）本サービスに関する各種事項の連絡や情報提供を行うため<br />
               （2）本サービスを提供するため<br />
               （3）本サービスにお客様登録する際に必要な情報入力を簡略化するため<br />
               （4）ご協力いただいた調査などに対する謝礼等をお送りするため<br />
               （5）ご応募いただいた懸賞などに対する景品等をお送りするため<br />
               （6）本サービスの利用状況等を調査・分析するため<br />
               （7）本サービスの内容をよりご満足いただけるよう改良・改善、又は新サービス開発のため<br />
               （8）本サービスに関する満足度を調査するため<br />
               （9）取得情報を統計的に処理した情報を、集約し調査結果として公表するため<br />
               （10）本サービス並びに当社及び第三者の新サービスその他の各種サービス等のご案内やお知らせをお届けするため<br />
               （11）調査のご協力依頼や各種イベントへのご参加をお願いしたり、その結果などをご報告するため<br />
               （12）本サービス運営上のトラブルの解決のため<br />
               （13）上記の利用目的に付随する目的
         </p>
         <p>
               <b>第４条（個人情報の第三者提供）</b><br />
               １. 当社は、次に掲げる場合を除いて、あらかじめお客様の同意を得ることなく、第三者に個人情報を提供することはありません。<br />
               （1）法令に基づく場合<br />
               （2）人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき<br />
               （3）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき<br />
               （4）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき<br />
               2. 前項の定めにかかわらず、次に掲げる場合は第三者提供には該当しないものとします。<br />
               （1）当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合。なお、委託先における個人情報の取扱いについては当社が責任を負います。<br />
               （2）合併その他の事由による事業の承継に伴って個人情報が提供される場合<br />
               （3）当社が、第5条に定めるところにより、個人情報の共同利用を行う場合
         </p>
         <p>
               <b>第５条（個人情報の共同利用）</b><br />
               １. 当社間での共同利用<br />
               当社は、第3条規定の利用目的の達成に必要な範囲で、当社が取得した個人情報を、当社と同等の個人情報保護方針で運営し、かつ当社と個人情報保護に関する秘密保持契約をした業務提携先などと共同で利用します。<br />
               （1）共同して利用される情報<br />
               第2条1項並びに第1条に規定のプライバシー情報<br />
               （2）共同して利用する者の範囲<br/>株式会社メルメクス及び業務提携先<br/>
               （3）共同利用する者の利用目的<br />
               第3条規定の利用目的の達成のため<br />
               （4）共同利用における管理責任者<br />株式会社メルメクス
         </p>
         <p>
               <b>第６条（個人情報の開示）</b><br />
               １. 当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。<br />
               （1）本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合<br />
               （2）当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合<br />
               （3）その他法令に違反することとなる場合<br />
               ２. 前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
         </p>
         <p>
               <b>第７条（個人情報の訂正および削除）</b><br />
               １. お客様は、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正または削除を請求することができます。<br />
               ２. 当社は、お客様から前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正または削除を行い、これをお客様に通知します。
         </p>
         <p>
               <b>第８条（プライバシー情報の利用停止等）</b><br />
               当社は、本人から、プライバシー情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、プライバシー情報の利用停止等を行い、その旨本人に通知します。ただし、プライバシー情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。<br />
         </p>
         <p>
               <b>第９条（クッキーなどの使用について）</b><br />
               （1）クッキー情報に関して、単独では特定の個人を識別することはできません。 個人情報が一体となって使用される場合には個人情報とみなします。<br />
               （2）クッキーの使用を許可するかどうかはお客様が設定できます。 多くのWebブラウザでは、自動的にクッキーの使用が許可されますが、クッキーの使用を禁止するようにブラウザの設定を変更することも可能です。 クッキーの使用を禁止した場合、本サービスを正常にご利用できない、あるいはクッキーを必要とする広告設定を反映できなくなる可能性があります。<br />
               （3）当社は、お客様のクッキー情報や、閲覧された広告・ページ、お客様のご利用環境等の情報を自動的にお客様のブラウザから受け取り、サーバーに記録し、本サービスログイン時の電子メールアドレス自動入力等の利便性向上のため、セッションの維持及び保護などセキュリティの為、また、新しいサービスを検討するため、サービスや広告の内容をよりお客様に適したものとするために利用いたします。
         </p>
         <p>
               <b>第１０条（ログの使用等について）</b><br />
               当社は、お客様が 本サービス訪問時に自動で生成される、IP アドレス、ブラウザ種類、ブラウザ言語などを収集します。これらの情報はお客様環境を分析し、より良いサービス提供のため、また正常なサービス提供を妨害する不正行為防止のために利用いたします。検索に関する記録は個人を特定することができない形態で保存及び管理され、統計データ作成などの用途で利用いたします。
         </p>
         <p>
               <b>第１１条（免責・注意事項）</b><br />
               １. 当社は、本サービスのリンクされている他の事業者または個人のウェブサービスにおけるお客様のプライバシー情報等の保護に関しまして、一切の責任を請け負いません。 お客様ご自身にて当該サービスの内容をよくご確認の上で、閲覧およびご利用をお願いいたします。<br />
               ２. ハッカー等による不当な行為により、お客様および第三者に損害が生じた場合に関してまして、当社は一切の責任を請け負いません。<br />
               ３. お客様に対して、ご本人確認をするための情報につきましては、お客様にて、紛失、忘失または第三者に知られることのないよう厳重な管理をお願いいたします。<br />
         </p>
         <p>
               <b>第１２条（プライバシーポリシーの変更）</b><br />
               １. 本ポリシーの内容は、お客様に通知することなく、変更することができるものとします。<br />
               ２. 当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。<br />
         </p>
         <p>
               <b>第１３条（お問い合わせ窓口）</b><br />
               本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。<br />
               株式会社メルメクス<br />
               150-0002　東京都渋谷区渋谷3-10-15<br />
               メールアドレス： <a href="mailto:info@melmax.co.jp">info@melmax.co.jp</a>
         </p>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
    name: "SectionPrivacy"
}
</script>